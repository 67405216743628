import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import Container from 'react-bootstrap/esm/Container';
import format from '../utils/format';


const Ephemeride = () => {
    const [eph, setEph] = useState();
    // useEffect est appellé au chargement du composant
    useEffect(() => {
        axios.get('/api/ephemeride').then((res) => {
            // console.log(res.data);
            setEph(res.data);
        })
    }, []);

    return (
        <Container>
            <div id="ephemeride">
                <h5><FontAwesomeIcon icon={solid('calendar-days')} /> Ephéméride du {eph ? format(new Date(eph.ephemeris.sunrise_time), "eeee dd MMMM yyyy") : ""} à Égliseneuve-près-Billom</h5>
                <div id="ephemeride_container">
                    <div className="ephemeride_block">
                        <ul>
                            <li className="figure">
                                <figure className="sun_up">
                                    <img src="/img/p1j.svg" alt="Lever du Soleil" title="Lever du Soleil" />
                                </figure>
                                <FontAwesomeIcon icon={solid('sort-up')} />
                            </li>
                            <li className="text">
                                <p>
                                    <small>Lever</small>
                                    <strong>{eph ? format(new Date(eph.ephemeris.sunrise_time), "HH:mm") : ""}</strong>
                                </p>
                            </li>
                            <li className="figure">
                                <figure className="sun_down">
                                    <img src="/img/p1j.svg" alt="Coucher du Soleil" title="Coucher du Soleil" />
                                </figure>
                                <FontAwesomeIcon icon={solid('sort-down')} />
                            </li>
                            <li className="text">
                                <p>
                                    <small>Coucher</small>
                                    <strong>{eph ? format(new Date(eph.ephemeris.sunset_time), "HH:mm") : ""}</strong>
                                </p>
                            </li>
                        </ul>
                        {/* <h3 className="saint_name">Marcellin</h3> */}
                    </div>
                    <div className="ephemeride_block">
                        <ul>
                            <li className="figure">
                                <figure className="moon_up">
                                    <img src="/img/p1n.svg" alt="Lever de la Lune" title="Lever de la Lune" />
                                </figure>
                                <FontAwesomeIcon icon={solid('sort-up')} />
                            </li>
                            <li className="text">
                                <p>
                                    <small>Lever</small>
                                    <strong>{eph ? format(new Date(eph.ephemeris.moonrise_time), "HH:mm") : ""}</strong>
                                </p>
                            </li>
                            <li className="figure">
                                <figure className="moon_down">
                                    <img src="/img/p1n.svg" alt="Coucher de la Lune" title="Coucher de la Lune" />
                                </figure>
                                <FontAwesomeIcon icon={solid('sort-down')} />
                            </li>
                            <li className="text">
                                <p>
                                    <small>Coucher</small>
                                    <strong>{eph ? format(new Date(eph.ephemeris.moonset_time), "HH:mm") : ""}</strong>
                                </p>
                            </li>
                        </ul>
                        <h3 className="lune_phase"><FontAwesomeIcon icon={solid('moon')} />&nbsp; {eph ? eph.ephemeris.moon_phase_description : ""}</h3>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Ephemeride;