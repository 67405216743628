import { format, compareAsc } from 'date-fns'
import { fr } from 'date-fns/locale'

// const locale = { fr }

export default function (date, formatStr = 'PP') {
    if (date instanceof Date) {
        return format(date, formatStr, {
            locale: fr
        })
    }
    else {
        return format(new Date(date), formatStr, {
            locale: fr
        })
    }

}