import React from 'react';
import format from '../utils/format';
import { Tooltip2 } from "@blueprintjs/popover2";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

const warningTemp = 4;

const ForecastDays = (props) => {

    if (props.forecasts) {
        props.forecasts.sort((a, b) => a.order - b.order);
        const forecasts = props.forecasts.length > 0 ? props.forecasts.map((f) => {

            return <>
                <div className='forecastHour'>
                    <div className="forecastItem">
                        <span className='empty' />
                        <span className='empty' />
                        <span className='source'>{f.source}
                            <img className='icon' src={f.source ? ('/img/' + f.source.normalize("NFD").toLowerCase().replace(/\p{Diacritic}/gu, "").replace(/ /g, '') + '-icon.png') : ''} alt={f.source} title={f.source}></img>
                        </span>
                    </div>
                    <div className="forecastItem">
                        <span className='empty' />
                        <span className='empty' />
                        <span className='empty' />
                        <span className='tmax row-head'>T. maxi</span>
                        <span className='tmin row-head'>T. mini</span>
                        {(f.source === "Météo France" || f.source === "Météo Concept" || f.source === "OpenWeatherMap") &&
                            <span className='precip row-head'>Précip.</span>
                        }
                        {(f.source === "La Chaine Météo" || f.source === "Météo Concept" || f.source === "OpenWeatherMap") &&
                            <span className='precip row-head'>Précip. %</span>
                        }
                    </div>

                    {f.daily_forecast ? f.daily_forecast.map((obj) => {
                        return <>

                            <div key={obj.time} className={"forecastItem " + (format(obj.time, 'E') === "sam." ? "we-s" : format(obj.time, 'E') === "dim." ? "we-d" : "")}>
                                <span className={"day " + (format(obj.time, 'E') === "sam." || format(obj.time, 'E') === "dim." ? "badge bg-success" : "")}>{format(obj.time, 'E')}</span>
                                <span className='day'>{format(obj.time, 'd MMM')}</span>
                                <Tooltip2 content={obj.daily_weather_description}>
                                    <img src={'/img/picto/' + obj.daily_weather_icon + '.png'} alt={obj.daily_weather_description} title={obj.daily_weather_description} />
                                </Tooltip2>
                                <span className={(obj.T_max > warningTemp) ? 'tmax' : "tmax badge bg-warning"}> {Math.round(obj.T_max)}</span>
                                <span className={(obj.T_min > warningTemp) ? 'tmin' : "tmin badge bg-warning"}> {Math.round(obj.T_min)}</span>
                                {(f.source === "Météo France" || f.source === "Météo Concept" || f.source === "OpenWeatherMap") &&
                                    <span className='precip'>{Math.round(obj.precip_quant) !== 0 ? Math.round(obj.precip_quant) + ' mm' : '-'}</span>
                                }
                                {(f.source === "La Chaine Météo" || f.source === "Météo Concept" || f.source === "OpenWeatherMap") &&
                                    <span className='precip'>{obj.precip_risk ? obj.precip_risk + " %" : obj.precip_risk == 0 ? '-' : '\u00A0'}</span>
                                }
                            </div>
                        </>;

                    }
                    ) : ''}
                </div>
            </>;
        }
        ) : ''

        return (
            <div className='forecastHours'>
                {forecasts}
            </div>
        );
    }
};

export default ForecastDays;