import React from 'react';
import format from '../utils/format';
import { Tooltip2 } from "@blueprintjs/popover2";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

function round5(x) {
    return Math.ceil(x / 5) * 5;
}
const warningTemp = 4;
const ForecastMoment = (props) => {

    const textWind = (wind) => {
        if (wind) {
            return (
                <> <span>
                    Vent : {round5(wind)} km/h
                </span> <br />
                </>)
        }
        else {
            return <span>Pas de vent.</span>
        }
    }
    const textWindGust = (windgust) => {
        if (windgust) {
            return (
                <> <span>
                    Rafales : {round5(windgust)} km/h
                </span> <br />
                </>)
        }
    }
    const textWindDir = (windir) => {
        if (windir && windir !== -1) {
            return (
                <>
                    <span>
                        Direction : {windir} °
                    </span>
                </>)
        }
    }

    const tooltipText = (wind, windgust, windir) => {
        return (
            <>
                {textWind(wind)}
                {textWindGust(windgust)}
                {textWindDir(windir)}
            </>
        );
    }

    if (props.forecasts) {
        props.forecasts.sort((a, b) => a.order - b.order);
        const forecasts = props.forecasts.length > 0 ? props.forecasts.map((f) => {
            return <>
                <div className='forecastHour forecastMoment'>
                    <div className="forecastItem">
                        <span className='empty' />
                        <span className='empty' />
                        <span className='empty' />
                        <span className='source'>{f.source}
                            <img className='icon' src={f.source ? ('/img/' + f.source.normalize("NFD").toLowerCase().replace(/\p{Diacritic}/gu, "").replace(/ /g, '') + '-icon.png') : ''} alt={f.source} title={f.source}></img>
                        </span>
                    </div>
                    <div className="forecastItem">
                        <span className='empty' />
                        <span className='empty' />
                        <span className='empty' />
                        <span className='empty' />
                        <span className='tmax row-head'>T.</span>

                        {(f.source === "Météo France" || f.source === "La Chaine Météo") &&
                            <span className='tfelt row-head'>T. ressentie</span>
                        }
                        <span className='precip row-head'>Précip. %</span>
                        <span className='wind row-head'>Vent</span>
                        <span className='empty'></span>
                    </div>

                    {
                        f.forecast ? f.forecast.map((obj) => {
                            return <>
                                <div key={obj.time} className={"forecastItem " + (format(obj.time, 'E') === "sam." ? "we-s" : format(obj.time, 'E') === "dim." ? "we-d" : "")}>
                                    <span className={"day " + (format(obj.time, 'E') === "sam." || format(obj.time, 'E') === "dim." ? "badge bg-success" : "")}>{format(obj.time, 'E')}</span>
                                    <span className='day'>{format(obj.time, 'd MMM')}</span>
                                    <span className='day'>{obj.moment_day}</span>
                                    <Tooltip2 content={obj.weather_description}>
                                        <img src={'/img/picto/' + obj.weather_icon + '.png'} alt={obj.weather_description} title={obj.weather_description} />
                                    </Tooltip2>
                                    <span className={(obj.T == null || obj.T > warningTemp) ? 'tmax' : "tmax badge bg-warning"}> {obj.T ? Math.round(obj.T) : "-"}</span>
                                    {(f.source === "Météo France" || f.source === "La Chaine Météo") &&
                                        <span className={(obj.T_windchill == null || obj.T_windchill > warningTemp) ? 'tfelt' : "tfelt badge bg-warning"}> {obj.T_windchill ? Math.round(obj.T_windchill) : "-"}</span>
                                    }
                                    <span className='precip'>{obj.precip_risk ? obj.precip_risk + " %" : obj.precip_risk == 0 ? '-' : '\u00A0'}</span>

                                    <Tooltip2 content={tooltipText(obj.wind_speed, obj.wind_speed_gust, obj.wind_direction)}>
                                        <div>
                                            <div className={obj.wind_speed <= 10 ? "wind wind-green" : obj.wind_speed <= 20 ? "wind wind-orange" : obj.wind_speed > 20 ? "wind wind-red" : "wind"}>
                                                <i className={obj.wind_speed > 5 ? "wi wi-wind from-" + obj.wind_direction + "-deg" : ""}></i>
                                                <i className={obj.wind_speed <= 5 ? "wi wi-moon-new" : ""}></i>

                                                <span className='wind-text'>
                                                    {obj.wind_speed > 5 ? round5(obj.wind_speed) + " " : ""}
                                                </span>
                                                <span className='wind-text-unit'>
                                                    {obj.wind_speed > 5 ? " km/h" : ""}
                                                </span>
                                            </div>

                                            <div className={obj.wind_speed_gust <= 10 ? "wind wind-green" : obj.wind_speed_gust <= 20 ? "wind wind-orange" : obj.wind_speed_gust > 20 ? "wind wind-red" : "wind"}>
                                                <span className='wind-text'>
                                                    {obj.wind_speed_gust ? "Raf " + round5(obj.wind_speed_gust) + " " : ""}
                                                </span>
                                                <span className='wind-text-unit'>
                                                    {obj.wind_speed_gust ? " km/h" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </Tooltip2>
                                </div>
                            </>;

                        }) : ''
                    }
                </div>
            </>
        }) : ''

        return (
            <div className='forecastHours forecastMoment'>
                {forecasts}
            </div>
        );
    }
};

export default ForecastMoment;