import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/weather-icons.min.css';
import './assets/styles/weather-icons-wind.min.css';
import './assets/styles/styles.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
