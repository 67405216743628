import React from 'react';
import Ephemeride from '../components/Ephemeride';
import Forecast from '../components/Forecast';
import Navigation from '../components/Navigation';

const Home = () => {
    return (
        <div>
            <Navigation />
            <Forecast />
            <Ephemeride />
        </div>
    );
};

export default Home;