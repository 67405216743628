import React from 'react';
import HourRain from "./HourRain"

const ForecastNow = (props) => {
    return (
        <div>
            <HourRain forecasts={props.forecasts} state={props.state} />
            <div class="containerMap">
                <iframe class="radarMap" src="https://www.meteoblue.com/fr/webview/weathermap/index/Égliseneuve-près-billom_france_3020348?velocityUnit=km/h&temperatureUnit=C&lengthUnit=metric#coords=7.56/45.467/3.231&map=radar~radarMapEU~none~none~none" ></iframe>
            </div>
        </div>
    );
};

export default ForecastNow;