import React, { useEffect, useState } from 'react';
import axios from 'axios';
import format from '../utils/format';

const HourRain = (props) => {

    const getRainImg = (intensity) => {
        let url = "/img/pas-de-pluie.svg";
        switch (intensity) {
            case 2:
                url = "/img/pluie-faible.svg";
                break;
            case 3:
                url = "/img/pluie-moderee.svg";
                break;
            case 4:
                url = "/img/pluie-forte.svg";
                break;
        }
        return url;
    }

    const getTime = (time) => {
        let t = new Date(time);
        return format(t, 'HH:mm');
    }

    const rainForecast =
        <div className="hour-rain-container">
            <h6><i className="wi wi-rain"> Pluie dans l'heure</i></h6>
            <div className="hour-rain-details">
                <div className="time"><p>{format(new Date(), 'HH:mm')}</p> <p>{format(new Date().setHours((new Date()).getHours() + 1), 'HH:mm')}</p></div>
                <ul className="rain-data">
                    {props.forecasts && props.forecasts.rain ? props.forecasts.rain.map(d => (
                        <li className={"rain-" + (d.rain_intensity - 1).toString()}>
                            <img alt={d.rain_intensity_description} title={d.rain_intensity_description} src={getRainImg(d.rain_intensity)}></img>
                            <div className="rain-data-legend">{getTime(d.time)}</div>
                        </li>
                    )) : ''}
                </ul>
            </div>
        </div>;

    return (
        <div>
            {rainForecast}
        </div>
    );


};

export default HourRain;