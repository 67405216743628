import React from 'react';
import Navigation from '../components/Navigation';

const Status = () => {
    return (
        <div className='container'>
            <Navigation />
            <h2>Statut des services</h2>
            <div><a href="https://meteofrance.com/previsions-meteo-france/egliseneuve-pres-billom/63160" target="_blank"> Météo France</a></div>
            <div><a href="https://www.lachainemeteo.com/meteo-france/ville-529737/previsions-meteo-egliseneuve-pres-billom-aujourdhui" target="_blank"> La Chaine Météo </a></div>
            <div><a href="https://www.meteo-concept.fr/previsions/public/ville/Egliseneuve-pres-Billom-63160/0/2" target="_blank"> Météo Concept</a></div>

        </div>
    );
};

export default Status; 