import React, { useEffect, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import ForecastHour from './ForecastHour';
import ForecastMoment from './ForecastMoment';
import ForecastDays from './ForecastDays';
import ForecastNow from './ForecastNow';

import { Route, Routes } from 'react-router-dom';
import axios from 'axios';

const Forecast = () => {
    const [forecastDay, setForecastDay] = useState([]);
    const [forecastHour, setForecastHour] = useState([]);
    const [forecastMoment, setForecastMoment] = useState([]);
    const [forecastRain, setForecastRain] = useState([]);
    const [rainState, setRainState] = useState("norain");
    const [state, setState] = useState("init");

    const updateForecastHour = (res) => {
        setForecastHour(prev => {
            prev = prev.filter(function (obj) {
                return obj.source !== res.data.source;
            });

            return [...prev, res.data];
        });
    }
    const updateForecastMoment = (res) => {
        setForecastMoment(prev => {
            prev = prev.filter(function (obj) {
                return obj.source !== res.data.source;
            });

            return [...prev, res.data];
        });
    }
    const updateForecastDay = (res) => {
        setForecastDay(prev => {
            prev = prev.filter(function (obj) {
                return obj.source !== res.data.source;
            });

            return [...prev, res.data];
        });
    }

    const getData = () => {
        // Daily forecast
        axios.get('/api/forecast-moment/meteofrance').then((res) => {
            res.data.order = 1;
            updateForecastDay(res);
            updateForecastMoment(res);
            // setState("load1");
        });
        axios.get('/api/forecast/lachainemeteo').then((res) => {
            res.data.order = 2;
            updateForecastDay(res);
            // setState("load2");
        });
        axios.get('/api/forecast/meteoconcept').then((res) => {
            res.data.order = 3;
            updateForecastDay(res);
            // setState("load4");
        });
        axios.get('/api/forecast/openweathermap').then((res) => {
            res.data.order = 4;
            updateForecastDay(res);
        });

        // Moment forecast
        axios.get('/api/forecast-moment/lachainemeteo').then((res) => {
            res.data.order = 2;
            updateForecastMoment(res);
        });
        axios.get('/api/forecast-moment/meteoconcept').then((res) => {
            res.data.order = 3;
            updateForecastMoment(res);
        });

        // Hourly forecast
        axios.get('/api/forecast/meteofrance').then((res) => {
            res.data.order = 1;
            updateForecastHour(res);
        });
        axios.get('/api/forecast-hour/lachainemeteo').then((res) => {
            res.data.order = 2;
            updateForecastHour(res);
        });
        axios.get('/api/forecast-hour/meteoconcept').then((res) => {
            res.data.order = 3;
            updateForecastHour(res);
        });
    }

    const getRainData = () => {
        // Rain Forecast
        axios.get('/api/forecast-rain/meteofrance').then((res) => {
            setForecastRain(res.data);

            setRainState("norain");
            if (res.data) {
                res.data.rain.some(e => {
                    if (e.rain_intensity != 1) {
                        setRainState("rain");
                        return true;
                    }
                });
            }
        });
    }

    // useEffect est appellé au chargement du composant
    useEffect(() => {
        getData();
        getRainData();
        const longInterval = setInterval(() => {
            getData()
        }, 1000 * 60 * 15) // every 15 min

        const shortInterval = setInterval(() => {
            getRainData()
        }, 1000 * 60 * 2) // every 2 min

        return () => {
            clearInterval(longInterval);
            clearInterval(shortInterval);
        }
    }, []);

    return (
        <Container>
            {/* <ForecastNavigation /> */}
            <Navbar expand="sm">
                <Container className='forecastNav'>
                    <Navbar.Brand >
                        Prévisions
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <LinkContainer to="now" >
                                <Nav.Link className='forecastNavLink'>Maintenant {rainState === "rain" ? <i className="wi wi-rain"> </i> : ""} </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="forecastHour" >
                                <Nav.Link className='forecastNavLink'>Par heure</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="">
                                <Nav.Link className='forecastNavLink'>Par &#188; jour</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="forecastDays">
                                <Nav.Link className='forecastNavLink'>&Agrave; 15 jours</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                <Route path="now" element={<ForecastNow forecasts={forecastRain} state={state} />} />
                <Route path="forecastHour" element={<ForecastHour forecasts={forecastHour} state={state} />} />
                <Route path="/*" element={<ForecastMoment forecasts={forecastMoment} state={state} />} />
                <Route path="forecastDays" element={<ForecastDays forecasts={forecastDay} state={state} />} />
            </Routes>
        </Container>
    );
};

export default Forecast;

